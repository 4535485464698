@import '../../styles/_vars';

.mobile-cta-toggler {
  display: block;
  width: 100%;
  height: 48px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 499;

  &:active {
    opacity: 0.95;
  }
}

.mobile-cta {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;

  .background {
    background: black;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.8;
    transition: opacity 150ms;
    will-change: opacity;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);

    .scroll {
      overflow-y: auto;
      pointer-events: all;
    }

    .top,
    .bottom {
      width: 100%;
      min-height: 0;
    }

    .bottom {
      flex-shrink: 0;
    }
  }

  &:not(.open) {
    pointer-events: none;

    .background {
      opacity: 0;
    }

    .flex-wrapper {
      transform: translateY(100%);
    }
  }

  .top {
    background: white;
    border-bottom: 1px solid #eee;
    position: relative;
    padding: 30px 20px;

    .close {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;
      padding: 0;

      svg {
        fill: #333;
        display: block;
      }
    }

    .headline {
      color: #32699f;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      text-align: center;
      margin-bottom: 28px;
    }

    .form {
      &__field {
        &:not(:last-of-type) {
          margin-bottom: 18px;
        }

        label {
          color: #32699f;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 1px;
          text-transform: uppercase;
          display: block;
          margin-bottom: 8px;

          &::after {
            content: '*';
            color: #fd4d4d;
            margin-left: 2px;
          }
        }

        .field {
          color: #333;
          font-size: 14px;
          border: 2px solid #eee;
          display: block;
          width: 100%;
          height: 64px;
          box-sizing: border-box;
          padding: 1px 20px 0;
          transition: border-color 150ms;

          &::placeholder {
            color: fade-out(black, 0.7);
          }

          &:focus,
          &:not(:empty) {
            border-color: #32699f;
          }
        }

        &--invalid .field {
          border-color: #ff6c6c;
        }
      }

      .hidden-submit-button {
        display: none;
      }
    }
  }

  .bottom {
    background: white;
    padding: 20px;
    overflow: hidden;

    .btn {
      height: 48px;

      &:active {
        opacity: 0.95;
      }
    }
  }
}
