.section-location {
  position: relative;
  margin-top: -320px;
  padding-top: 670px;
  overflow: hidden;

  @media (max-width: 950px) {
    padding-top: 400px;
    margin-top: -300px;
  }

  &::before {
    content: '';
    background: linear-gradient(to right, #d5e7f7, #e7fffd);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -2;
  }

  .background {
    width: 100%;
    position: absolute;
    width: 1512px;
    height: 1401px;
    top: 0;
    bottom: 285px;
    left: 0;
    overflow: hidden;
    z-index: -1;

    @media (max-width: 950px) {
      max-width: 100%;
      height: auto;
      opacity: 0.3;
    }

    @media (max-width: 768px) {
      top: auto;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .container {
    position: relative;
    z-index: 3;
  }

  .top {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 70px;

    @media (max-width: 1024px) {
      justify-content: center;
    }

    @media (max-width: 650px) {
      margin-bottom: 40px;
    }

    .content {
      width: 575px;
      max-width: 100%;

      @media (max-width: 1024px) {
        text-align: center;
      }

      .title {
        margin-bottom: 40px;

        img {
          display: inline-block;
          max-width: 100%;
          margin-bottom: -73px;

          @media (max-width: 650px) {
            width: 350px;
            margin-bottom: -32px;
          }

          @media (max-width: 500px) {
            margin-bottom: 10px;
          }
        }

        .bottom {
          color: #32699f;
          font-size: 28px;
          font-weight: 300;
          text-transform: uppercase;
          padding-left: 153px;

          @media (max-width: 1024px) {
            padding-left: 30px;
          }

          @media (max-width: 650px) {
            padding-left: 50px;
          }

          @media (max-width: 500px) {
            font-size: 22px;
            font-weight: normal;
            padding-left: 0;
          }
        }
      }

      .text {
        padding-right: 120px;

        @media (max-width: 1024px) {
          padding-right: 0;
        }

        p {
          color: #666;
          line-height: 28px;
          margin: 0;

          @media (max-width: 500px) {
            font-size: 14px;
            line-height: 24px;
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }

      .cta {
        margin-top: 30px;
        
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          text-decoration: unset;
          width: 200px;
        }
      }
    }
  }

  .map {
    width: 100%;
    height: 570px;
    box-shadow: 0 50px 100px fade-out(black, 0.5);

    @media (max-width: 1024px) {
      height: 50vw;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
