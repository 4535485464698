.section-gallery {
  position: relative;
  padding-top: 100px;

  &::before {
    content: '';
    background: linear-gradient(to right, #d5e7f7, #e7fffd);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .title {
    text-align: center;
    margin-bottom: 60px;

    .top {
      color: #32699f;
      font-size: 28px;
      font-weight: 300;
      text-transform: uppercase;
    }

    img {
      display: inline-block;
      max-width: 100%;
    }
  }

  .images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    position: relative;
    z-index: 2;

    @media (max-width: 650px) {
      grid-gap: 10px;
    }

    @media (max-width: 510px) {
      display: block;
    }

    @media (min-width: 1023px) {
      .col:nth-child(1) {
        .item:nth-child(1) {
          height: 219px;
        }

        .item:nth-child(2) {
          height: 226px;
        }

        .item:nth-child(3) {
          height: 276px;
        }

        .item:nth-child(4) {
          height: 230px;
        }

        .item:nth-child(5) {
          height: 303px;
        }

        .item:nth-child(6) {
          height: 265px;
        }
      }

      .col:nth-child(2) {
        .item:nth-child(1) {
          height: 288px;
        }

        .item:nth-child(2) {
          height: 241px;
        }

        .item:nth-child(3) {
          height: 303px;
        }

        .item:nth-child(4) {
          height: 268px;
        }

        .item:nth-child(5) {
          height: 279px;
        }

        .item:nth-child(6) {
          height: 245px;
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;

      &:nth-child(1) {
        align-items: flex-end;

        @media (max-width: 510px) {
          margin-bottom: 10px;
        }
      }

      &:nth-child(2) {
        align-items: flex-start;
      }

      .item {
        position: relative;

        @media (max-width: 510px) {
          width: 100%;
          min-height: 100px;
        }

        &:not(:last-child) {
          margin-bottom: 24px;

          @media (max-width: 650px) {
            margin-bottom: 10px;
          }
        }

        .caption {
          color: white;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1px;
          text-shadow: 0 2px 8px fade-out(black, 0.6);
          text-transform: uppercase;
          position: absolute;
          z-index: 2;

          &.top-left {
            top: 20px;
            left: 20px;
          }

          &.top-right {
            top: 20px;
            right: 20px;
          }

          &.bottom-left {
            bottom: 20px;
            left: 20px;
          }

          &.bottom-right {
            right: 20px;
            bottom: 20px;
          }

          @media (max-width: 600px) {
            &.top-left {
              top: 10px;
              left: 10px;
            }

            &.top-right {
              top: 10px;
              right: 10px;
            }

            &.bottom-left {
              bottom: 10px;
              left: 10px;
            }

            &.bottom-right {
              right: 10px;
              bottom: 10px;
            }
          }
        }

        .image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          @media (max-width: 510px) {
            width: 100%;
          }
        }
      }
    }
  }
}
