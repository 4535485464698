@import '../../styles/_vars';

.section-call-to-action {
  position: relative;
  margin-top: -285px;
  padding: 400px 0 160px;

  @media (max-width: 860px) {
    margin-top: -25vw;
    padding: calc(25vw + 80px) 0 80px;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content-wrapper {
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-gap: 100px;
    width: 950px;
    max-width: 100%;
    margin: 0 auto;

    @media (max-width: 1024px) {
      grid-gap: 40px;
    }

    @media (max-width: 860px) {
      display: block;
    }
  }

  .content {
    @media (max-width: 860px) {
      text-align: center;
    }

    .title {
      position: relative;
      left: -2px;
      margin-bottom: 40px;

      @media (max-width: 550px) {
        margin-bottom: 22px;
      }

      img {
        display: inline-block;
        max-width: 100%;
        margin-bottom: -30px;

        @media (max-width: 550px) {
          width: 400px;
          margin-bottom: 0;
        }
      }

      .bottom {
        color: white;
        font-size: 28px;
        font-weight: 300;
        letter-spacing: 4px;
        line-height: 40px;
        text-transform: uppercase;
        position: relative;
        z-index: 2;

        @media (max-width: 550px) {
          font-size: 22px;
          font-weight: normal;
          letter-spacing: 0;
          line-height: 36px;
        }

        @media (max-width: 350px) {
          font-size: 20px;
        }
      }
    }

    .text {
      @media (max-width: 860px) {
        width: 500px;
        max-width: 100%;
        margin: 0 auto;
      }

      p {
        color: white;
        line-height: 28px;
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }

  .form-wrapper {
    @media (max-width: 860px) {
      display: none;
    }

    .form {
      &__fields {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        margin-bottom: 40px;
      }

      &__field {
        border-bottom: 1px solid white;
        transition: border-color 150ms;

        label {
          color: white;
          display: block;
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 12px;

          &::after {
            content: '*';
            color: #ff6c6c;
            margin-left: 4px;
          }
        }

        .field {
          color: white;
          font-family: 'Montserrat';
          font-size: 14px;
          font-weight: 600;
          background: none;
          border: none;
          display: block;
          width: 100%;
          padding-bottom: 8px;
          outline: none;

          &::placeholder {
            color: fade-out(white, 0.2);
            transition: opacity 150ms;
          }

          &:focus::placeholder {
            opacity: 0;
          }
        }

        &--invalid {
          border-color: #ff6c6c;
        }
      }
    }
  }
}
