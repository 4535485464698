.section-footer {
  background: #009a44;
  position: relative;
  padding: 70px 0 30px;

  &::before {
    content: '';
    background: #043a5c;
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1024px) {
    padding: 0;

    &::before {
      content: none;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 140px;
    position: relative;
    z-index: 2;

    @media (max-width: 1220px) {
      grid-gap: 100px;
    }

    @media (max-width: 1024px) {
      display: block;
      max-width: 100%;
    }
  }

  .left {
    @media (max-width: 1024px) {
      background: #043a5c;
      padding: 40px 20px;
    }

    .logo {
      margin-bottom: 50px;

      @media (max-width: 1024px) {
        display: flex;
        justify-content: center;
      }
    }

    .contact-numbers {
      border-top: 1px solid white;
      margin-bottom: 40px;
      padding-top: 28px;

      .headline {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        @media (max-width: 1024px) {
          justify-content: center;
        }

        .icon {
          margin-right: 18px;
        }

        span {
          color: #8bff79;
          font-weight: 600;
        }
      }

      .numbers {
        display: flex;
        align-items: center;

        @media (max-width: 1024px) {
          justify-content: center;
        }

        @media (max-width: 400px) {
          text-align: center;
          display: block;
        }

        a {
          color: white;
          font-size: 20px;
          font-weight: 600;
          text-decoration: none;
          position: relative;
          display: block;
          transition: color 150ms;
          pointer-events: none;

          &:hover {
            color: fade-out(white, 0.3);
          }

          @media (min-width: 401px) {
            &:not(:last-child) {
              margin-right: 24px;
              padding-right: 22px;
              border-right: 2px solid white;
            }
          }

          @media (max-width: 400px) {
            &:not(:last-child) {
              margin-bottom: 18px;
            }
          }

          span {
            pointer-events: all;
          }

          svg {
            position: absolute;
            top: -2px;
            right: -30px;
            transform: scale(.6);

            @media (max-width: 768px) {
              right: unset;
            }
          }
        }
      }
    }

    .copyright {
      color: #2377ac;
      font-size: 12px;
      font-weight: 600;

      @media (max-width: 1024px) {
        text-align: center;
      }
    }
  }

  .right {
    @media (max-width: 1024px) {
      padding: 40px 20px;
    }

    @media (max-width: 568px) {
      padding-bottom: 88px;
    }

    .top {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 70px;
      margin-bottom: 40px;

      @media (max-width: 1220px) {
        grid-gap: 40px;
      }

      @media (max-width: 550px) {
        text-align: center;
        display: block;
      }

      .item {
        @media (max-width: 550px) {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        &__title {
          color: #043a5c;
          font-weight: bold;
          margin-bottom: 24px;
        }

        a,
        img {
          display: inline-block;
        }
      }
    }

    .bottom {
      .headline {
        color: #043a5c;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        &::after {
          content: '';
          background: #043a5c;
          display: block;
          flex-grow: 1;
          height: 1px;
          margin-left: 20px;
        }
      }

      .items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 70px;

        @media (max-width: 550px) {
          text-align: center;
          display: block;
        }

        .item {
          @media (max-width: 550px) {
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }

          &__title {
            color: white;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 18px;
          }

          a,
          img {
            display: inline-block;
          }
        }
      }
    }
  }
}
