.section-video {
  position: relative;
  padding-top: 160px;

  @media (max-width: 1040px) {
    padding-top: 120px;
  }

  @media (max-width: 768px) {
    padding-top: 90px;
  }

  @media (max-width: 400px) {
    padding-top: 60px;
  }

  &::before {
    content: '';
    background: linear-gradient(to right, #d6e8f7, white);
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
  }

  .container {
    position: relative;
  }

  .background {
    position: absolute;
    width: 634px;
    height: 709px;
    right: 0;
    bottom: 200px;
    pointer-events: none;

    @media (max-width: 1040px) {
      opacity: 0.3;
      right: auto;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .title {
    color: #32699f;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 4px;
    line-height: 52px;
    text-transform: uppercase;
    margin: -14px 0 115px;

    @media (max-width: 1040px) {
      text-align: center;
      margin-bottom: 60px;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 50px;
    }

    @media (max-width: 550px) {
      letter-spacing: 0;
    }

    @media (max-width: 500px) {
      font-size: 24px;
    }

    @media (max-width: 400px) {
      font-size: 18px;
      font-weight: normal;
      line-height: 40px;
      margin: 0 0 30px;
    }

    .top,
    .bottom {
      position: relative;
      z-index: 2;
    }

    img {
      display: inline-block;
      position: relative;
      z-index: 1;
      margin: -70px 0 -60px;

      @media (max-width: 768px) {
        margin: -50px 0 -40px;
      }

      @media (max-width: 550px) {
        width: 380px;
        margin: -40px 0 -30px;
      }

      @media (max-width: 400px) {
        width: 280px;
        margin: -30px 0 -20px;
      }
    }
  }

  .video-player {
    width: 970px;
    height: 400px;
    max-width: 100%;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    box-shadow: 0 50px 100px fade-out(black, 0.5);

    @media (max-width: 1040px) {
      width: 100%;
      height: 360px;
    }

    @media (max-width: 550px) {
      height: 260px;
    }

    @media (max-width: 400px) {
      height: 180px;
    }

    .play-button {
      background: fade-out(black, 0.6);
      border: none;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      outline: none;
      transition: background-color 150ms;

      &:hover {
        background: fade-out(black, 0.8);
      }

      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
      }

      &::before {
        background: white;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        margin-top: -35px;
        margin-left: -35px;
      }

      &::after {
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-left: 20px solid black;
        width: 0;
        height: 0;
        z-index: 2;
        margin-top: -10px;
        margin-left: -7px;
      }
    }

    .thumbnail {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;;
      }
    }
  }
}
