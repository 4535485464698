.form .form__loader {
  background: fade-out(black, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 150ms, visibility 150ms;
  will-change: opacity;

  &-message {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    background: white;
    border-radius: 4px;
    max-width: calc(100% - 30px);
    padding: 24px 35px;
    transform: scale(1.05);
    box-shadow: 0 10px 20px fade-out(black, 0.9);
    transition: transform 150ms;

    p {
      font-size: 14px;
      line-height: 22px;
      margin: 0;
    }
  }

  &.open {
    opacity: 1;
    visibility: visible;

    .form__loader-message {
      transform: scale(1);
    }
  }
}

.form.styled {
  .form__field {
    border-bottom: 1px solid;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    label {
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 2px;
      text-transform: uppercase;
      display: block;
      margin-bottom: 14px;
    }

    .field {
      font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
      font-size: 14px;
      background: none;
      border: none;
      display: block;
      width: 100%;
      resize: none;
      padding-bottom: 10px;

      &::placeholder {
        transition: opacity 250ms;
        will-change: opacity;
      }

      &:focus::placeholder {
        opacity: 0;
      }
    }
  }
}
