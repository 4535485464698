.section-evolution {
  position: relative;
  padding: 100px 0 50px;

  &::before {
    content: '';
    background: linear-gradient(to right, #d5e7f7, #e7fffd);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .section-title {
    color: #32699f;
    font-size: 28px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;

    /** Mobile. */
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 65px;

    /** Mobile. */
    @media (max-width: 767px) {
      width: 100%;
      margin-top: 30px;
    }

    img {
      display: inline-block;
      width: auto;
      user-select: none;
      pointer-events: none;
      max-width: 100%;

      /** Mobile. */
      @media (max-width: 767px) {
        width: 100%;
        max-width: 320px;
      }
    }
  }
}