@import '../../styles/_vars';

.section-hero {
  height: 750px;
  position: relative;

  @media (max-width: 876px) {
    height: auto;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    @media (max-width: 876px) {
      padding: 34px 0 42px;
    }

    .logo {
      flex-shrink: 0;
      padding-top: 36px;

      @media (max-width: 876px) {
        text-align: center;
        padding: 0;
      }

      img {
        display: inline-block;
        max-width: 100%;

        @media (max-width: 480px) {
          width: 180px;
        }
      }
    }

    .content {
      display: flex;
      align-items: center;
      flex-grow: 1;
      padding: 36px 70px;

      @media (max-width: 1024px) {
        padding: 36px 0;
      }

      @media (max-width: 876px) {
        display: block;
        margin-top: 36px;
        padding: 0;
      }

      .left {
        flex-grow: 1;
      }

      .right {
        width: 330px;
        flex-shrink: 0;
      }
    }
  }

  .content .left {
    color: white;

    @media (max-width: 876px) {
      text-align: center;
    }

    .title {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      @media (max-width: 876px) {
        align-items: center;
      }

      .text {
        font-size: 36px;
        letter-spacing: 2px;
        text-transform: uppercase;

        @media (max-width: 568px) {
          font-size: 24px;
        }

        @media (max-width: 480px) {
          font-size: 18px;
          font-weight: 400;
          letter-spacing: 0;
        }
      }

      img {
        max-width: 100%;

        @media (max-width: 568px) {
          margin-top: 20px;
        }

        @media (max-width: 480px) {
          width: 240px;
          max-width: 100%;
        }
      }
    }

    .headline {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      width: 400px;
      max-width: calc(100% - 40px);
      margin-top: 30px;

      @media (max-width: 876px) {
        margin: 30px auto 0;
      }

      @media (max-width: 480px) {
        font-size: 14px;
        line-height: 20px;
        margin-top: 20px;
      }

      @media (max-width: 414px) {
        max-width: 100%;
      }

      p {
        margin: 0;
      }
    }

    .features {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px 30px;
      width: 500px;
      max-width: 100%;
      margin-top: 60px;

      @media (max-width: 876px) {
        margin: 60px auto 0;
      }

      @media (max-width: 480px) {
        grid-gap: 20px;
        margin-top: 40px;
      }

      @media (max-width: 414px) {
        display: inline-block;
        width: auto;
        margin-top: 30px;
      }

      .feature {
        text-align: left;
        display: flex;
        align-items: center;

        @media (max-width: 480px) {
          text-align: center;
          border: 1px solid fade-out(white, 0.5);
          display: block;
          padding: 14px 4px 10px;
        }

        @media (max-width: 414px) {
          text-align: left;
          display: flex;
          padding: 12px 16px 9px;

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 41px;
          height: 41px;
          margin-right: 18px;

          @media (max-width: 480px) {
            display: inline-block;
            margin: 0 0 8px;
          }

          @media (max-width: 414px) {
            position: relative;
            top: 1px;
            margin-right: 16px;
          }
        }

        .text {
          color: white;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 2px;
          line-height: 14px;
          text-transform: uppercase;

          @media (max-width: 480px) {
            letter-spacing: 1px;
            padding-left: 1px;
          }

          @media (max-width: 414px) {
            font-size: 12px;
            font-weight: 600;
            line-height: 18px;
          }
        }
      }
    }
  }

  .content .right {
    @media (max-width: 876px) {
      display: none;
    }

    .call-to-action {
      width: 330px;
      max-width: 100%;
      box-shadow: 0 14px 30px fade-out(black, 0.6);

      .headline {
        color: white;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        background: black;
        padding: 18px 12px 16px;
      }

      .form-wrapper {
        background: white;
        padding: 30px;

        .form {
          &__fields {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            margin-bottom: 30px;
          }

          &__field {
            border-bottom: 1px solid fade-out(black, 0.85);
            transition: border-color 150ms;

            label {
              color: black;
              display: block;
              font-size: 14px;
              font-weight: 600;
              margin-bottom: 12px;

              &::after {
                content: '*';
                color: #ff6c6c;
                margin-left: 4px;
              }
            }

            .field {
              color: black;
              font-family: 'Montserrat';
              font-size: 14px;
              background: none;
              border: none;
              display: block;
              width: 100%;
              padding-bottom: 8px;
              outline: none;

              &::placeholder {
                color: fade-out(black, 0.7);
                transition: opacity 150ms;
              }

              &:focus::placeholder {
                opacity: 0;
              }
            }

            &--invalid {
              border-color: #ff6c6c;
            }
          }
        }
      }
    }
  }

  .scroll-indicator {
    display: flex;
    align-items: center;
    position: absolute;
    right: -50px;
    bottom: 100px;
    transform: rotate(-90deg);

    @media (max-width: 1024px) {
      display: none;
    }

    .icon {
      transform: rotate(90deg);
      margin-right: 20px;
    }

    .text {
      color: white;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      display: block;
      animation: scrollIndicator 2.5s ease infinite;

      @keyframes scrollIndicator {
        0%,
        100% {
          transform: translateX(0);
        }

        50% {
          transform: translateX(10px);
        }
      }
    }
  }
}
