.section-intro {
  background: linear-gradient(to right, #d6e8f7, white);
  padding-top: 80px;

  @media (max-width: 768px) {
    padding-top: 50px;
  }

  .container {
    display: grid;
    grid-template-columns: 570px 1fr;
    grid-gap: 80px;

    @media (max-width: 1140px) {
      grid-gap: 40px;
      grid-template-columns: 400px 1fr;
    }

    @media (max-width: 950px) {
      grid-gap: 20px;
      grid-template-columns: 260px 1fr;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  .featured-image {
    width: 100%;
    height: 540px;

    @media (max-width: 1140px) {
      height: auto;
    }

    @media (max-width: 768px) {
      height: 200px;
      margin-bottom: 40px;
    }

    @media (max-width: 400px) {
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: inline-block;
      max-width: 100%;
    }
  }

  .content {
    .title {
      color: #32699f;
      font-size: 28px;
      font-weight: 300;
      line-height: 52px;
      text-transform: uppercase;
      margin: -14px 0 40px;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        .top br {
          display: none;
        }
      }

      @media (max-width: 500px) {
        font-size: 24px;
      }

      @media (max-width: 400px) {
        font-size: 18px;
        font-weight: normal;
        margin: 0 0 10px;
      }

      img {
        margin: -34px 0 -20px;

        @media (max-width: 768px) {
          margin: -24px 0 -10px;
        }

        @media (max-width: 500px) {
          width: 360px;
        }

        @media (max-width: 400px) {
          width: 280px;
          margin: -14px 0 -14px;
        }
      }
    }

    .text p {
      color: #666;
      line-height: 28px;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      @media (max-width: 768px) {
        text-align: center;
      }

      @media (max-width: 520px) {
        text-align: left;
      }
    }
  }
}
