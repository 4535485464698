#main-whatsapp {
  position: fixed;
  top: 50px;
  right: 35px;
  z-index: 45;

  @keyframes whatsapp_pulse {
    0% {
      transform: scale(.9) translate(-50%, -50%);
      opacity: .5;
    }
    100% {
      transform: scale(2) translate(-50%, -50%);
      opacity: 0;
    }
  }

  &::before,
  &::after {
    content: '';
    background-color: #32b17a;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    transform-origin: 0% 0%;
    transform: scale(.9) translate(-50%, -50%);
    animation-name: whatsapp_pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.5, 0, 0, 1);
    z-index: 1;
  }

  &::after {
    animation-delay: 1.5s;
  }

  a {
    background-color: #32b17a;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 60px;
    height: 60px;
    z-index: 2;
    border-radius: 50%;
  }

  /** Mobile. */
  @media (max-width: 1200px) {
    top: unset;
    right: 15px;
    bottom: 65px;
  }
}