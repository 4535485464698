@import './styles/_general';

body {
  font-family: 'Montserrat';
}

.container {
  width: 1200px;
  max-width: calc(100% - 40px);
  margin: 0 auto;
}

[rel='lazy_load'],
[rel='lazy_load_new'] {
  overflow: hidden;

  img {
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease(inout);
  }

  &.loaded {
    img {
      opacity: 1;
    }
  }
}
