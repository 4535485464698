$mdc-primary: #79b55a;
$mdc-secondary: #ffb400;

@keyframes buttonGradient {
  0% {
    background-position: left center;
  }

  100% {
    background-position: right center;
  }
}
