.section-features {
  position: relative;
  margin-top: -200px;
  padding: 320px 0 180px;

  @media (max-width: 550px) {
    margin-top: -130px;
    padding: 200px 0 80px;
  }

  .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .title {
    color: white;
    font-size: 28px;
    line-height: 42px;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 80px;

    @media (max-width: 550px) {
      font-size: 24px;
      margin-bottom: 60px;
    }

    @media (max-width: 450px) {
      font-size: 18px;
      line-height: 30px;
    }
  }

  .features {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 50px;
    width: 760px;
    max-width: 100%;
    margin: 0 auto;

    @media (max-width: 650px) {
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 30px;
    }

    @media (max-width: 360px) {
      grid-template-columns: 1fr 1fr;
    }

    .feature {
      .icon {
        border: 1px solid fade-out(white, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        margin: 0 auto 20px;
      }

      .text {
        color: white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;

        @media (max-width: 450px) {
          font-size: 11px;
          letter-spacing: 0;
        }

        @media (max-width: 360px) {
          font-size: 13px;
          line-height: 20px;
        }
      }
    }
  }
}
