@import './_vars';

.el-img {
  display: block;
  pointer-events: none;
  user-select: none;

  &__cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.btn {
  color: white;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-image: linear-gradient(
    to right,
    $mdc-primary,
    $mdc-secondary,
    $mdc-primary,
    $mdc-secondary
  );
  background-size: 300%;
  background-position: left center;
  border: none;
  display: block;
  width: 100%;
  height: 60px;
  box-shadow: 0 8px 20px fade-out($mdc-primary, 1);
  animation: buttonGradient 10s linear infinite;
  transition: box-shadow 150ms;

  @media (min-width: 769px) {
    &:hover {
      box-shadow: 0 6px 16px fade-out($mdc-primary, 0.2);
    }
  }
}
